<script module lang="ts">
	export type BorderStyle = 'solid' | 'dashed' | 'dotted';
	export const BORDER_SIZES = [0, 1, 2, 3, 4, 5, 6] as const;
	export const BORDER_RADII = [0, 1, 2, 3, 4, 5, 6, 7, 8] as const;
	export type BorderSize = (typeof BORDER_SIZES)[number];
	export type BorderRadius = (typeof BORDER_RADII)[number];
	export type BorderShadow = 'none' | 'small' | 'medium' | 'large';

	export type BorderRadiusOptions = {
		radiusTopLeft?: BorderRadius;
		radiusTopRight?: BorderRadius;
		radiusBottomLeft?: BorderRadius;
		radiusBottomRight?: BorderRadius;
	};

	type BorderPositionOptions = {
		sizeLeft?: BorderSize;
		sizeRight?: BorderSize;
		sizeTop?: BorderSize;
		sizeBottom?: BorderSize;
	};

	type TProps = {
		fullWidth?: boolean;
		fullHeight?: boolean;
		colour?: TColours;
		size?: BorderSize;
		borderStyle?: BorderStyle;
		radius?: BorderRadius;
		children?: Snippet;
		additionalClasses?: string;
		'data-testid'?: string;
	} & BorderRadiusOptions &
		BorderPositionOptions;
</script>

<script lang="ts">
	import type { TColours } from '$lib/data/colours';
	import { styles } from '$lib/utilities/core/styles';
	import classNames from 'classnames';
	import _ from 'lodash';
	import type { Snippet } from 'svelte';

	const {
		fullWidth = false,
		fullHeight = false,
		colour,
		size = 2,
		sizeLeft,
		sizeRight,
		sizeTop,
		sizeBottom,
		borderStyle,
		radius = 1,
		radiusTopLeft,
		radiusTopRight,
		radiusBottomLeft,
		radiusBottomRight,
		additionalClasses,
		children,
		...restProps
	}: TProps = $props();

	const borderClasses = $derived(
		classNames(
			'Border',
			{
				'Border--fullWidth': fullWidth,
				'Border--fullHeight': fullHeight,
				[`Border--colour-${colour}`]: colour,
				[`Border--radius-${radius}`]: radius,
				[`Border--radius-topLeft-${radiusTopLeft}`]: _.isNumber(radiusTopLeft),
				[`Border--radius-topRight-${radiusTopRight}`]: _.isNumber(radiusTopRight),
				[`Border--radius-bottomLeft-${radiusBottomLeft}`]: _.isNumber(radiusBottomLeft),
				[`Border--radius-bottomRight-${radiusBottomRight}`]: _.isNumber(radiusBottomRight),
				'Border--size': _.isNumber(size),
				'Border--size-left': _.isNumber(sizeLeft),
				'Border--size-right': _.isNumber(sizeRight),
				'Border--size-top': _.isNumber(sizeTop),
				'Border--size-bottom': _.isNumber(sizeBottom),
				[`Border--borderStyle-${borderStyle}`]: borderStyle,
			},
			additionalClasses,
		),
	);

	const styling = $derived(
		styles({
			'--Border-size': size,
			'--Border-size-left': sizeLeft,
			'--Border-size-right': sizeRight,
			'--Border-size-top': sizeTop,
			'--Border-size-bottom': sizeBottom,
		}),
	);
</script>

<div class={borderClasses} style={styling} {...restProps}>
	{#if children}
		{@render children()}
	{/if}
</div>

<style lang="scss">
	@import '$lib/styles/colours.scss';

	$maxSize: 6;
	$maxRadius: 8;
	.Border {
		$self: &;
		position: relative;
		display: inline-flex;
		border: var(--half-padding) solid transparent;

		&--fullWidth {
			width: 100%;
		}

		&--fullHeight {
			height: 100%;
		}

		&--borderStyle {
			&-solid {
				border-style: solid;
			}

			&-dashed {
				border-style: dashed;
			}

			&-dotted {
				border-style: dotted;
			}
		}

		&--size {
			border-width: calc(var(--half-padding) * var(--Border-size));
		}

		@each $colour in $colours {
			&--colour-#{$colour} {
				border-color: var(--#{$colour});

				// We add the background when it's not a dashed or dotted border so that we don't have the super tiny thing white spacing between the border and the content
				&#{$self} {
					&:not(&--borderStyle-dashed, &--borderStyle-dotted) {
						background-color: var(--#{$colour});
					}
				}
			}
		}

		&--size-left {
			border-left-width: calc(var(--half-padding) * var(--Border-size-left));
		}

		&--size-right {
			border-right-width: calc(var(--half-padding) * var(--Border-size-right));
		}

		&--size-top {
			border-top-width: calc(var(--half-padding) * var(--Border-size-top));
		}

		&--size-bottom {
			border-bottom-width: calc(var(--half-padding) * var(--Border-size-bottom));
		}

		&--radius-full {
			border-radius: 100%;
		}

		@mixin childRadiusSizes($radius, $property) {
			// For each fo the radiuses, create a size modifier that adds border radius to the child .Plane based on the width of the border and the size of the radius
			@for $size from 1 through $maxSize {
				&#{$self}--size {
					:global(> .Plane) {
						#{$property}: calc(
							var(--half-padding) * max(($radius - var(--Border-size)), 0)
						);
					}
				}
			}
		}

		@for $radius from 0 through $maxRadius {
			&--radius-#{$radius} {
				border-radius: calc(var(--half-padding) * $radius);
				@include childRadiusSizes($radius, 'border-radius');
			}
		}

		@for $radius from 0 through $maxRadius {
			&--radius-topLeft-#{$radius} {
				border-top-left-radius: calc(var(--half-padding) * $radius);
				@include childRadiusSizes($radius, 'border-top-left-radius');
			}

			&--radius-topRight-#{$radius} {
				border-top-right-radius: calc(var(--half-padding) * $radius);
				@include childRadiusSizes($radius, 'border-top-right-radius');
			}

			&--radius-bottomLeft-#{$radius} {
				border-bottom-left-radius: calc(var(--half-padding) * $radius);
				@include childRadiusSizes($radius, 'border-bottom-left-radius');
			}

			&--radius-bottomRight-#{$radius} {
				border-bottom-right-radius: calc(var(--half-padding) * $radius);
				@include childRadiusSizes($radius, 'border-bottom-right-radius');
			}
		}
	}
</style>
